import { NextPageProps, TNextRedirect, TPageId } from '../types';
import { GetServerSidePropsContext } from 'next';
import { getTruxwebServerSideProps } from '../pageProps';
import { LoginPageV2 } from '../pageContents';
import React from 'react';

const PAGE_ID: TPageId = 'login';
const PAGE_LANGUAGE = 'en-CA';
const REQUIRED_NAMESPACES = ['common', 'signup', PAGE_ID];

export const Login = (): JSX.Element => {
  return <LoginPageV2 pageId={PAGE_ID} />;
};

export const getServerSideProps = async (
  context: GetServerSidePropsContext
): Promise<NextPageProps | TNextRedirect> => {
  return await getTruxwebServerSideProps(
    context,
    {
      pageId: PAGE_ID,
      pageLanguage: PAGE_LANGUAGE,
      requiredNamespaces: REQUIRED_NAMESPACES,
    },
    true
  );
};

export default Login;
